import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import picOffice01 from '../assets/images/office-01.jpg'

const EmployeeBenefits = (props) => (
    <Layout>
        <Helmet>
            <title>Generic - Forty by HTML5 UP</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Case Study: Offices</h1>
                    </header>
                    <span className="image main"><img src={picOffice01} alt="" /></span>
                    <h2>Culture: Work Hard, Play Hard</h2>
                    <p>
                        Culture is one of the most vital aspects when measuring a health of a company. 
                        The moral and engagement of a company's employees is one of the leading indicators of the direction a business is heading.
                        This is why building and maintaining company culture is imparitive, and often begins by defining a motto around the desired culture you want to portray.
                    </p>
                    <p>
                        One of fastest growing mottos in the workplace is "Work Hard, Play Hard".
                        In fact, there are many reason you should adapt this as your mantra.
                        (<a href="https://blog.arkadin.com/en/how-a-work-hard-play-hard-culture-builds-better-companies/" target="_blank">additional reading</a>)
                        Typically, the "WHPH" motto results in promoting a work environment where tasks get done, but with a lighter fun vibe then the stereotypical baige corporate culture.
                        To help facilitate this type of culture by curating a variety of employee perks.
                        (Some of the more popular ones you'll find are ping pong tables, casual attire, or health program.)
                        The downside to many of these trendy employee perks is the lower ROI they bring to the business.
                        Either the perk looses it's shine and becomes stale, or it comes with an associated high cost that minimizes the value.
                    </p>
                    <h2>ROI: Team Building</h2>
                    <p>
                        When it comes to directly adding ROI, there are few things that can come close to team building.
                        The reason team building ranks so high, is that it can't be forced and there is no easy solution.
                        In fact, <a href="https://www.forbes.com/sites/brianscudamore/2016/03/09/why-team-building-is-the-most-important-investment-youll-make/" target="_blank">this Forbes article</a> puts it best by saying, 
                        "The challenge is creating opportunities for people to connect and interact in meaningful ways, outside of regular meetings or presentations."
                    </p>
                    {/* <h2>Board Games</h2>
                    <p>
                         
                    </p> */}
                    <h2>What Does Parlour Party Do Differently?</h2>
                    <p>
                        We are board game experts who care about the pain-points companies face with meeting the needs of a work hard, play hard culture.
                        This is why we want to partner with you to provide your employees with a board game perk that we service to ensure that you are getting the ROI that your company deserves.
                    </p>
                    <h4>Need More Specifics?</h4>
                    <p>
                        We provide you with flyers and newsletters to provide to your employees to highlight featured games and ways to take advantage.
                        <a href="/assets/Office-Sample-Flyer.jpg" target="_blank"> Click here</a> to view a sample flyer of all the advantages of taking a break to play a game with your coworkers.
                    </p>
                    <p>
                        As always, you can <Link to="/how-it-works">click here</Link> to learn more about the foundation of our Parlour Party service and what to expect.
                    </p>
                    <h2>Additional Reading</h2>
                    <ul>
                        <li><a href="https://escreo.com/blog/en/7-office-games/" target="_blank">Office Games - Escreo</a></li>
                        <li><a href="https://www.learningapprentice.com/building-a-team-with-board-games/" target="_blank">Building A Team With Board Games - Learning Apprentice</a></li>
                        <li><a href="https://medium.com/startup-grind/bro-ditch-the-table-tennis-5ff05cde65b8" target="_blank">Bro Ditch The Table Tennis - Medium</a></li>
                    </ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default EmployeeBenefits